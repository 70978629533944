const myCompany = [
    {
        key: 'news',
        label: 'News',
        icon: 'newspaper',
        route: { params: { page: 'news' }, name: 'dashboard' },
        permissions: ['view', 'news'],
        children: [
            {
                key: 'general-news',
                label: 'General News',
                icon: 'newspaper',
                permissions: ['view general news', 'general-news'],
                route: { name: 'general-news' },
            },
            {
                key: 'internal-news',
                label: 'Internal News',
                icon: 'newspaper',
                permissions: ['view internal news', 'internal-news'],
                route: { name: 'internal-news' },
            },
        ],
    },
    {
        key: 'employees',
        label: 'Employees',
        icon: 'users',
        permissions: ['view members', 'members'],
        route: { name: 'listing', params: { configName: 'employees' } },
    },
    {
        key: 'profile',
        label: 'Profile',
        icon: 'user',
        route: { name: 'update', params: { configName: 'profile', id: 0 } },
    },
    {
        key: 'impersonation',
        label: 'Impersonation',
        icon: 'user',
        permissions: ['can impersonate', 'impersonate'],
        route: { name: 'listing', params: { configName: 'impersonation' } },
    },
    {
        key: 'roles',
        label: 'Roles',
        icon: 'users',
        permissions: ['view roles', 'roles'],
        route: { name: 'listing', params: { configName: 'roles' } },
    },
    {
        key: 'website',
        label: 'Website',
        icon: 'newspaper',
        route: { params: { page: 'website' }, name: 'dashboard' },
        permissions: ['blocks-management', 'website'],
        children: [
            {
                key: 'blocks',
                label: 'Blocks Management',
                icon: 'newspaper',
                route: { name: 'listing', params: { configName: 'blocks' } },
            },
            {
                key: 'posts',
                label: 'Posts',
                icon: 'newspaper',
                route: { name: 'listing', params: { configName: 'posts' } },
            },
        ],
    },
]

export default myCompany
